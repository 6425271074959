export const environment = {
  production: false,
  environment_name: 'dev',
  client_id: 'b10740f2-555f-4fbb-a1c6-9bdd5c8f0df0',
  tenant_autority: 'https://login.microsoftonline.com/77184d99-6065-42b9-9452-8b2b70385193',
  redirect_url: 'https://ko-cde-in-frontend-global.azurewebsites.net/auth-response',
  custom_scope: 'api://b10740f2-555f-4fbb-a1c6-9bdd5c8f0df0/user_impersonation',

  api_base_url: 'https://rgm-planogram-be.azurewebsites.net/',
  //sas:'Yi0oL/FTXMVT1GqmKEtg57gshyWxIw15o+AyhcC27qnHfk9ljLPzzG4Fw+Z6u1yp3tfNqYEZ+wln+AStEYJGug==',
  // Enter your storage account name
 //picturesAccount: "kocdeglobalstorage"
 picturesAccount :'rgmdemostorage',
 sas:'sp=rw&st=2024-04-08T07:38:58Z&se=2025-12-31T15:38:58Z&sv=2022-11-02&sr=c&sig=0U8NCyryHCxe%2BR25Czfeg0PD74FNfijdiiH9p%2FYog6Q%3D',
  // container name
  picturesContainer: "cooler-images",
  // user:'admin',
  // pwd:'SuperSecretPwd',
  // token: localStorage.getItem('token') || '',
  // token: document.cookie.split('token=')[1] ? document.cookie.split('token=')[1] : '',
  // token:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJleHBpcnlfZGF0ZSI6MTY1MTI3NjgwMH0.QCcHbO22MlD2cvFPSOS2RgY-XGE9HV2zRHiu3hvGD9w'
  hubble_dashboard_url:"https://hubble.decisionpoint.in/",
  hubble_dashboard_backend_url:"https://hubble-be-new.azurewebsites.net/"
};

//https://rgmdemostorage.blob.core.windows.net/cooler-images?sp=racwdl&st=2022-11-03T02:25:15Z&se=2023-11-03T10:25:15Z&sv=2021-06-08&sr=c&sig=dVe43TyAEvHlVlKTPDBLfaotwhtHOsaCgN6M6JuTZoo%3D
